input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.madalPaid {
    width:100%;
    height: 60px;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
    border-radius: 35px;
    background-color: whitesmoke;
    margin: auto;
    margin-bottom: 20px;
    display: flex;

}
.modalText {
    font-size: 18px;
    margin-left: 20px;
    color: #b009aa;
}
.inpCalc1 {
    width: 94%;
    height: 50px;
    margin-bottom: 20px;
    margin-top: 5px;
    border: 0px solid #000000;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
    border: 0.5px solid rgba(0, 0, 0, 0.3);
    border-radius: 35px;
    padding-left: 3%;
    padding-right: 3%;
    font-size: 18px;
    color: #000000;
}
.inpCalc1::placeholder{
    color: #949494;
    font-size: 16px;
}
.inpCalc1:focus {
    outline: none; /* Убираем стандартную обводку */
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2); /* Создаем тень */
  }

.btnCalc {
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
    margin-top: 20px;
    border: 0px solid #000000;
    border-radius: 35px;
    font-size: 18px;
    background-color: white;
    cursor: pointer;
    color: #666666;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1)
}
.btnCalc:hover{
    opacity: 0.7;
}
.addBtn {
    padding: 10px;
    border-radius: 35px;
    cursor: pointer;
    text-align: center;
    color: #000000;
    border: none;
    background-color: white;
    width: 100%;
    font-size: 18px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05)
}
.addBtn:hover{
    opacity: 0.6;
}
@media only screen and (max-width: 900px) {
    .inpCalc1 {
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;
}
}