.allArt {
    display: flex;
    flex-direction: row;
   justify-content: space-between; 
   padding-left: 5%;
   padding-right: 5%;
   padding-top: 20px;
  }
  .leftAllArt {
    width: 45%;
  }
  .rightAllArt {
    width: 50%;
    text-align: left;
    padding-top: 50px;
    padding-bottom: 40px;
  }
  @media only screen and (max-width: 900px) {
    .allArt {
      display: block;
      padding-left: 5%;
      padding-right: 5%;
    } 
    .leftAllArt {
      width: 95%;
    }
    .rightAllArt {
      width: 95%;
      text-align: left;
      padding-top: 50px;
      padding-bottom: 40px;
    }
  }