.appHome {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 20px;
}
.leftHomeCont {
  width: 45%;
}
.codeEditorCont {
  width: 50%;
}
@media only screen and (max-width: 900px) {
  .appHome {
    display: block;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
  } 
  .leftHomeCont {
    width: 95%;
  }
  .codeEditorCont {
    width: 95%;
  }
}

