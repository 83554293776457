.contLoading {
    width: 100%;
    height: 100vh;
    margin: auto;
    /* display: flex; */
    /* flex-direction: column;
    justify-content: center; */
    padding-top: 100px;
    background-color: rgba(250, 240, 240, 0.5);
    text-align: center;


}
.contLoading img {
    width: 100px;
    height: 100px;
    animation: rotate 2s linear infinite;
    /* transform-style: preserve-3d; */
}
@keyframes rotate {
    from {
      transform: rotateY(180deg); /* Угол начала анимации (0 градусов) */
    }
    to {
      transform: rotateY(540deg); /* Угол окончания анимации (360 градусов) */
    }
  }
