.contLeftHome {
    width: 100%;
    /* margin: auto; */
    /* background-color: blue; */
}
.headLeft{
    color:#b009aa;
    font-size: 66px;
    font-weight: bolder;
    margin: 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.contH {
    /* background-color: rgba(250, 240, 240, 0.5); */
    /* border: solid black 1px; */
    box-shadow:-5px -5px 10px -3px rgba(71, 10, 10, 0.1); /* Создаем тень */
    padding: 20px;
    padding-bottom: 1px;
    border-radius: 5px;
    margin-bottom: 40px;
    margin-top: 30px;
}
.contLink {
    /* background-color: rgba(250, 240, 240, 0.5); */
    /* border: solid black 1px; */
    box-shadow:0px 5px 5px -5px rgba(0, 0, 0, 0.1); 
    padding: 20px;
    padding-bottom: 50px;
    /* border-radius: 35px; */
    margin-top: 60px;
}

@media only screen and (max-width: 900px) {
    .contLeftHome {
        width: 100%;
        padding:0;
    }
    .leftHomeHeader {
        margin-top: 30px;
    }
    .headLeft{
        color:#b009aa;
        font-size: 60px;
        font-weight: bolder;
        margin: 0;
    }
}