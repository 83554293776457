.contTerms {
    background-color: rgba(250, 240, 240, 0.5);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
    padding: 20px;
    border-radius: 35px;
    margin-bottom: 30px;
    position: relative;
    margin-top: 20px;
}
.termsLi {
    margin-bottom: 10px;
}
.termsImg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}
.termsImg:hover {
    width: 35px;
    height: 35px;
    top: 15px;
    right: 15px;
}