.contCount {
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    margin-top: 20px;
    margin-bottom: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.countDoc {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    color: #666666;
    font-weight: bold;
    /* text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2); */
}
.listDown {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 15px;
}
.eachList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90px;
    height: 90px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 50%;
    /* box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05); */
    background-color: rgba(250, 240, 240, 0.5);
    border:5px solid rgb(214, 214, 214);
    text-align: center;
}
.numberDoc {
    font-size: 22px;
    font-weight: bold;
    margin: 0;
    color: #b009aa;
    /* border-bottom: 1px solid rgb(250, 195, 195); */
}
.nameDoc {
    font-size: 10px;
    margin: 0;
    color: #000000;
}
@media screen and (min-width: 700px) and (max-width: 900px) {
    .listDown {
        justify-content: space-around;
    }
}
@media screen and (min-width: 371px) and (max-width: 400px) {
    .eachList {
        width: 85px;
        height: 85px;
    }
}
@media screen and (min-width: 280px) and (max-width: 370px) {
    .eachList {
        width: 75px;
        height: 75px;
    }
    .numberDoc {
        font-size: 18px;
    }
    .nameDoc {
        font-size: 9px;
    }
}