.articleCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
}
.leftArticle {
    width: 45%;
}
.articleRight {
    width: 50%;

}
.infoArticle {
    padding: 20px;
    border-radius: 35px;
    /* background-color:rgba(250, 240, 240, 0.5) ; */
    margin-top: 50px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05)
}

.headLink {
    /* background-color: aqua; */
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    /* margin-left: 50px; */
    margin-top: 10px;
    width: 450px;
    box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.2)
}
.headLeft{
    color:#b009aa;
    font-size: 66px;
    font-weight: bolder;
    margin: 0;
}
.docPaidCont {
    padding: 20px;
    border-radius: 35px;
    /* background-color: rgba(250, 240, 240, 0.5); */
    margin-top: 50px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05)
}
.formCont {
    padding: 20px;
    padding-top: 0px;
    border-radius: 25px;
    background-color: rgba(250, 240, 240, 1);
    margin-top: 30px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    border: 0.5px solid rgb(201, 198, 198);
}
.btnFill {
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.07);
    border: 0.5px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    color: #666666 ;
    width: 100%;
    height: 40px;
    font-size: 15.5px;
    cursor: pointer;
    background-color: white;
    font-weight: bold;
}
.btnFill:hover {
    opacity: 0.5;
}
.warning {
    margin-top: 20px;
    /* box-shadow: 0px 0px 2px 2px rgba(71, 10, 10, 0.1) ; */
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    padding: 20px;
    /* background-color: rgba(250, 240, 240, 0.5); */
}
@media only screen and (max-width: 900px) {
    .articleCont {
        display: flex;
        flex-direction: column;
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 20px;
        
    }
    .leftArticle {
        width: 100%;
        padding: 0px;
    }
    .articleLoginFoot {
        width: 100%;
        float: right;
        text-align: center;
        margin-bottom: 50px;
        margin-top: 20px;
    }
    .articleRight {
        width: 100%;
        padding-left: 0px;
        display: flex;
        padding-top: 20px;
    }
    .headLeft{
        color:#b009aa;
        font-size: 60px;
        font-weight: bolder;
        margin: 0;
    }

}
@media only screen and (max-width: 450px) {
    .headLink {
        /* background-color: aqua; */
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        /* margin-left: 50px; */
        margin-top: 10px;
        width: 300px;
        box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.2)
    }

}