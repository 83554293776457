.contAllArt {
    /* background-color: rgba(250, 240, 240, 0.5); */
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
    padding: 20px;
    border-radius: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
}
.titleRight {
    color: #666666;
    margin: 0;
    font-size: 24px;
}
.hRight {
    margin-bottom: 30px;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

.contentRight {
    color: #666666;
}
.BtnRight {
    /* background-color: rgba(250, 240, 240, 0.5); */
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 35px;
    cursor: pointer;
    text-align: center;
    color: #666666;
}
.BtnRight:hover{
    opacity: 0.7;
}
.imgCont{
    display: flex;
    /* align-items: center; */
}
.imgRight {
    width: 35%;
    border-radius: 15px;
    margin-bottom: 15px;
    margin-right: 15px;
}
@media screen and (min-width: 601px) and (max-width: 900px) {
    .imgRight {
        width: 25%;
        border-radius: 15px;
        margin-bottom: 15px;
        margin-right: 15px;
    }
}
@media screen and (min-width: 901px) and (max-width: 1100px) {
    .imgCont{
        display: block;
    }
    .imgRight {
        width: 100%;
        border-radius: 25px;
        margin-bottom: 15px;
    }
    .contAllArt {
        /* background-color: rgba(250, 240, 240, 0.5); */
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
        padding: 20px;
        border-radius: 35px;
        margin-bottom: 20px;
        margin-top: 10px;
        display: block;
    }
    .hRight {
        margin-bottom: 30px;
        margin-left: 20px;
    }
}
@media only screen and (max-width: 600px) {
.imgCont{
    display: block;
}
.imgRight {
    width: 100%;
    border-radius: 25px;
    margin-bottom: 15px;
}
.contAllArt {
    /* background-color: rgba(250, 240, 240, 0.5); */
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
    padding: 20px;
    border-radius: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    display: block;
}
}