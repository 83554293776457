
.discribeBlock {
    color:#666666;
    font-size: 14px;
}

.cards {
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(530px, 1fr)); */
    grid-gap: 20px;
    padding-bottom: 20px;
    padding-right: 1%;
    padding-left: 1%;
}

.card {
    display: grid;
    grid-template-rows: max-content 100px 1fr;
    padding: 3%;
    padding-top: 0;
    padding-bottom: 0;
    /* background-color: #0B0C10; */
}
.content {
    text-align: center;
    height:  auto;


}
.img {
    object-fit:contain;
    width: 100px;
    height: 100px;
    margin-top: 0;
    padding: 0;
}