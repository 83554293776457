.loginFootCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 5%;
    padding-right: 5%;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
    /* background-color:#f8f8f8; */
    margin-top: 20px;
}
.bottomLoginFoot {
    display: flex;
    flex-direction: column;
}
.contRekviz {
    display: flex;
    flex-direction: column;

}
.rekvis {
    /* line-height:-inherit; */
    color: #666666;
    margin: 0;
    font-size: 15px;
}
.divTerms {
    padding-left: 5%;
    padding-right: 5%;
}

@media only screen and (max-width: 800px) {
    .loginFootCont {
        display: flex;
        flex-direction: column;
    }
    .bottomLoginFoot {
        margin-bottom: 20px;
    }
}