.codeCont {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.inpHeader {
    text-align: left;
    padding-top: 50px;
    padding-bottom: 40px;
}
.inpCont {
    padding-bottom: 20px;

}
.inputInp {
    width: 94%;
    height: 50px;
    padding-left: 3%;
    padding-right: 3%;
    font-size: 17px;
    border: 1px solid #000000;
    border-radius: 35px;
    outline: none; 
    margin-bottom: 20px;
}
.inputInp:focus {
    outline: none; /* Убираем стандартную обводку */
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2); /* Создаем тень */
  }
.hCode {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);

}
.codeBottom {
    padding-top: 10px;
}
@media screen and (min-width: 901px) and (max-width: 1100px) {
    .hCode {
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
        margin-left: 20px;
    }
}

@media only screen and (max-width: 900px) {
    .inputInp {
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;
    }

    .codeBottom {
        padding-top: 10px;
    }
}